@import '../../assets/scss/bootstrap';

.summaryWrapper {
	background-color: $darkBlue;
	text-align: center;
	padding-top: 16px;

	@include media-breakpoint-up(sm) {
		text-align: left;
		display: flex;
		justify-content: space-between;
		padding-top: 33px;
	}
	@include media-breakpoint-up(xxl) {
		padding-top: 53px;
	}
}

.smallText {
	font-size: 0.75rem;
}