@import '../../assets/scss/bootstrap';

.header {
	border-top: 1px solid $gray-300;
	border-bottom: 1px solid $darkBlue;
	padding: 1.9rem 0;
}

.title {
	font-size: 1.125rem;
	color: $darkBlue;
	font-weight: bold;
	display: inline;
	line-height: 1em;
}

.free,
.reserved,
.taken {
	font-size: 0.75rem;
	font-weight: bold;
	color: $light;
	text-align: right;
	display: inline;
	margin-right: 1rem;

	&:before {
		content: '';
		display: inline-block;
		width: 1em;
		height: 1em;
		background-color: $grayBlue;
		border-radius: 50%;
		margin-right: .5em;
	}
}

.reserved:before {
	background-color: $gray-200;
}

.taken:before {
	background-color: $red;
}

.list {
	margin: 0;
	padding: 0;
	float: right;

	li:last-child {
		margin-right: 0;
	}
}

.navPrev,
.navNext {
	position: absolute;
	display: flex;
	align-items: center;
	font-size: 0.625rem;
	font-weight: 300;
	color: $blue;
	right: 0;
	text-align: right;

	&:after {
		content: '';
		display: inline-block;
		width: 26px;
		height: 24px;
		background-repeat: no-repeat;
		background-size: contain;
		margin-left: .5rem;
	}
}

.navPrev {
	top: 0;
	&:after {
		background-image: url('../../assets/svg/arrow-top.svg');
	}
}

.navNext {
	bottom: 0;
	&:after {
		background-image: url('../../assets/svg/arrow-bottom.svg');
	}
}