@import './bootstrap';

.DateRangePicker {
	display: block;
	margin-left: 30px;
}

.DateRangePickerInput {
	background-color: transparent;
	border: none;
}

.DateInput {
	border-radius: 1.56rem;
	margin: 0 1rem;
}

.DateInput_input {
	border-radius: 1.56rem;
	border: 1px solid $blue;
	text-align: center;
}

.DateInput_input__focused {
	box-shadow: 0 0 0 0.2rem rgba($blue, .25);
	color: #495057;
	background-color: #fff;
	border-color: #4dd2ff;
	outline: 0;
}

.DateRangePickerInput_arrow {
	display: none;
}

.CalendarDay {
	font-size: 0.75rem;
	border-color: transparent;
	border-radius: 50%;

	&:hover {
		border-color: transparent;
	}
}

.CalendarDay__default {
	color: $grayBlue;
}

.CalendarDay__blocked_out_of_range, .CalendarDay__blocked_out_of_range:active, .CalendarDay__blocked_out_of_range:hover {
	color: $gray-300;
}

.CalendarDay__selected {
	color: white;
	background-color: $darkBlue;

	&:hover {
		background-color: rgba($darkBlue, .8);
	}
}

.CalendarDay__selected_start {
	border-radius: 50% 0 0 50%;
}

.CalendarDay__selected_end {
	border-radius: 0 50% 50% 0;
}

.CalendarDay__selected_span {
	background-color: $blue;
	border-radius: 0;
	color: white;

	&:hover {
		background-color: rgba($blue, .8);
	}
}

.CalendarDay__hovered_span {
	background-color: $blue;
	border-radius: 0;
	color: white;

	&:hover {
		background-color: $darkBlue;
		border-radius: 0 50% 50% 0;
		color: white;
	}
}

.CalendarMonth_table {
	font-weight: bold;
}

.DayPicker_weekHeader_li {
	small {
		color: $darkBlue;
		font-size: 0.875rem;
		font-weight: bold;
		text-transform: capitalize;
	}
}

.CalendarMonth_caption {
	strong {
		font-size: 1.125rem;
		text-transform: capitalize;
		color: $darkBlue;
		font-weight: 300;
		padding-left: .8rem;
	}
}
.CalendarMonthGrid__vertical .CalendarMonth_caption {
	text-align: left;
}

.DayPickerNavigation__vertical {
	position: absolute;
	top: 16px;
	bottom: 16px;
	right: 16px;
}

.DayPicker_weekHeader_ul {
	white-space: nowrap;
}

.CalendarDay__blocked_calendar, .CalendarDay__blocked_calendar:active, .CalendarDay__blocked_calendar:hover {
	background-color: $red;
	color: white;
}

.CalendarDay__highlighted_calendar,
.CalendarDay__highlighted_calendar:active,
.CalendarDay__highlighted_calendar:hover {
	background-color: transparent;
	color: $gray-200;
}

.DateRangePickerInput_clearDates {
	right: initial;
	padding: 8px 10px;
}
