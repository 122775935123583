// Custom checkbox

.custom-checkbox .custom-control-label::before {
	border-color: $primary;
}

.custom-control-input:checked ~ .custom-control-label::before {
	background-color: transparent;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
	background-image: none;
	background-color: $primary;
	border-radius: 50%;
}

.custom-control-label::after {
	top: 0.5rem;
	left: -1.25rem;
	width: .5rem;
	height: .5rem;
}

.custom-checkbox--top-centered {
	.custom-control-label::after,
	.custom-control-label::before {
		left: 50%;
		transform: translateX(-50%);
	}
}

.custom-checkbox--left {
  text-align: left;
}

// Custom radio
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
	background-image: none;
	background-color: $primary;
	border-radius: 50%;
}

// Custom select and multiselect

.form-group [class*="css-"][class*="-control"],
select.form-control {
	background-image: url('../../assets/svg/arrow-bottom-sm.svg');
	background-size: 1rem;
	background-repeat: no-repeat;
	background-position: 94% center;
}

// Select as time picker

.selectAsTimePicker {
	.form-control {
		display: inline-block;
		width: 50%;
		background-image: url('../svg/arrow-up_down-sm.svg');
		background-position: 80% center;

		&:first-child {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}
		&:last-child {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
			margin-left: -1px;
		}
	}
}

// Disabled fieldset legend style

fieldset[disabled] {
	.form-label {
		color: $light;
	}
}

// Custom multiple select
.dropdown-heading {
	box-sizing: border-box !important;
	border-color: $primary !important;
	border-radius: $border-radius !important;
	height: 50px !important;
	outline: none;
	overflow: hidden;
	position: relative;
	width: 100%;

	&-value {
		padding: 8px 25px !important;
	}
}

.dropdown-content {
	border-color: $primary !important;
	padding: 10px 0 !important;
	text-align: left;
}
