@import '../../assets/scss/bootstrap';

.referenceBlock {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	align-items: center;
	background-color: $darkBlue;
	padding: $spacer * 3;
	max-width: 417px;
	margin: 0 auto;
	height: 100%;

	@include media-breakpoint-up(sm) {
		max-width: 100%;
	}
	@include media-breakpoint-only(lg) {
		padding: $spacer * 1.5;
	}

	p {
		font-family: 'Merriweather', serif;
	}

	&__author {
		font-size: 1rem;
		text-transform: uppercase;
		font-weight: bold;
		color: $blue;
		letter-spacing: .2em;
	}
}
