@import '../../assets/scss/bootstrap';

.input {
	display: block;
	position: relative;
	margin: 0 auto;
	margin-bottom: 32px;

	&:hover {
		cursor: pointer;
	}
}

.label {
	display: block;
	text-align: center;
	font-size: 1.125rem;
	line-height: 1.2em;
	padding-top: 40px;

	&:hover {
		cursor: pointer;
	}
}

.labelTitle {
	color: $darkBlue;
	text-transform: uppercase;
	font-weight: bold;
	margin-top: 20px;
}

.labelPrice {
	color: $blue;
}

.labelPersons {
	color: $light;
}