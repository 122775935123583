@import '../../assets/scss/bootstrap';

.wrapper {
	position: relative;
	max-height: 300px;
	overflow: hidden;

	@include media-breakpoint-up(xl) {
		max-height: 350px;
	}
	@include media-breakpoint-up(xxl) {
		max-height: 400px;
	}
}

.video {
	position: relative;
	// padding-bottom: 30.25%;
	padding-bottom: 54.25%;
	padding-top: 25px;
	height: 0;

	@include media-breakpoint-down(md) {
		padding-bottom: 52%;
	}

	@include media-breakpoint-up(md) {
		margin-top: -40px;
	}
	@include media-breakpoint-up(lg) {
		margin-top: -100px;
	}
	@include media-breakpoint-up(xl) {
		margin-top: -130px;
	}
	@include media-breakpoint-up(xxl) {
		margin-top: -150px;
	}
}

.iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
}

.overlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(#000, .6);
	z-index: 2;
}

.textWrap {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	z-index: 3;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	text-align: center;
	
	@include media-breakpoint-up (sm) {
		padding: 0 60px;
	}
}

.text {
	font-size: 2.1875rem;
	color: #fff;

	@include media-breakpoint-down (sm) {
		font-size: 1rem;
		font-weight: bold;
	}
}