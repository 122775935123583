@import '../../assets/scss/bootstrap';

.contactContent {
	font-size: 15px;

	@include media-breakpoint-up(xl) {
		padding-left: 25px;
	}

	h2 {
		@include font-size($h5-font-size);
		font-weight: 600;
		margin-top: 35px;
		margin-bottom: 10px;

		&:first-child {
			margin-top: 16px;
		}
	}

	a {
		text-decoration: underline;
		color: inherit;

		&:hover {
			text-decoration: none;
		}
	}
	a[href^="tel"] {
		color: $darkBlue;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}

	table {
		margin-left: -5px;
	}

	table a {
		display: inline-block;
		padding-right: 28px;
		background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIzLjAuNiwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IlZyc3R2YV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIKCSB2aWV3Qm94PSIwIDAgMjEgMjEuOSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjEgMjEuOTsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8cGF0aCBkPSJNMTAuNSwwQzQuNywwLDAsNC45LDAsMTAuOWMwLDYsNC43LDEwLjksMTAuNSwxMC45UzIxLDE3LDIxLDEwLjlDMjEsNC45LDE2LjMsMCwxMC41LDB6IE0xNi4xLDExLjNsLTIsMgoJYy0wLjIsMC4yLTAuNSwwLjQtMC44LDAuNGMtMC4zLDAtMC42LTAuMS0wLjgtMC40Yy0wLjUtMC41LTAuNS0xLjIsMC0xLjdsMC44LTAuOEg3LjhjLTAuNywwLTEuMiwwLjUtMS4yLDEuMnY0LjNINC4yVjEyCgljMC0yLDEuNi0zLjYsMy42LTMuNmg1LjVsLTAuOC0wLjhjLTAuNS0wLjUtMC41LTEuMiwwLTEuN2MwLjUtMC41LDEuMi0wLjUsMS43LDBsMiwyQzE3LjEsOC45LDE3LjEsMTAuNCwxNi4xLDExLjN6IiBmaWxsPSIjZmZmIiAvPgo8L3N2Zz4K");
		background-repeat: no-repeat;
		background-position: right center;
		background-size: 22px 22px;
	}
}