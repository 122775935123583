@import '../../assets/scss/bootstrap';

.cardTitle {
	font-size: 2.1875rem;
	color: $darkBlue;
	text-transform: uppercase;
	margin-left: 1rem;

	@include media-breakpoint-only(xs) {
		margin-top: 1rem;
		margin-left: 0;
	}
}

.cardHeader {
	display: flex;
	align-items: center;
	margin-bottom: 1rem;

	@include media-breakpoint-only(xs) {
		display: block;
		text-align: center;
	}
}

.cardContent {
	padding-left: calc(96px + 1rem);

	@include media-breakpoint-only(xs) {
		padding-left: 0;
		text-align: center;
	}
}

.cardCentered {
	text-align: center;

	.cardHeader {
		display: block;
	}
	.cardContent {
		padding-left: 0;
	}
}