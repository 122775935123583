@import '../../assets/scss/variables';

.miniLoader {
  position: fixed;
  top: $navbarHeight;
  width: 100%;
  height: 0;
  background: $blue;
  color: $white;
  transition: height 0.4s;
  text-align: center;
  font-size: 16px;
}

.miniLoaderShow {
  height: 32px;
  padding-top: 5px;
}


@keyframes loading {
  0% { transform: translate3d(0,   0  , 0) }
  10% { transform: translate3d(0, -5px, 0) }
  50% { transform: translate3d(0,   0  , 0) }
}

.miniDot {
  display: inline-block;
  width: 5px;
  height: 5px;
  margin: 6px 1px 2px 1px;
  background-color: $white;
  border-radius: 50%;
  animation: loading 2.5s ease 0s infinite;
}

.miniDot:nth-of-type(2) { animation-delay: 0.1s }
.miniDot:nth-of-type(3) { animation-delay: 0.2s }
