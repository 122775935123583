@import '../../assets/scss/bootstrap';

.name {
	font-size: 1.125rem;
	color: $darkBlue;
	font-weight: bold;
	text-transform: uppercase;
}

.list {
	list-style: none;
	padding: 0;
	margin: 0;

	@include media-breakpoint-up(xl) {
		display: inline-block;
	}

	li {
		font-size: 1rem;
		color: $light;
		line-height: 1.3em;

		strong {
			font-weight: 300;
			color: $blue;
		}
	}
}

.bg {
	background-color: $gray-200;
	padding: 20px;

	@include media-breakpoint-up (md) {
		padding: 20px 40px;
	}
}

@include media-breakpoint-up (xxl) {
	.bg {
		padding: 59px 50px;
	}

	.margin {
		margin-left: 50px;
	}
}
