@import '../../assets/scss/bootstrap';

// Hamburger menu icon settings
$hamburger-padding-x: 5px;
$hamburger-padding-y: 15px;
$hamburger-layer-width: 35px;
$hamburger-layer-height: 2px;
$hamburger-layer-spacing: 6px;
$hamburger-layer-color: #fff;
$hamburger-layer-border-radius: 0;
$hamburger-hover-opacity: 1;
$hamburger-active-layer-color  : $hamburger-layer-color;
$hamburger-active-hover-opacity: $hamburger-hover-opacity;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter   : false;
$hamburger-hover-filter       : opacity(50%);
$hamburger-active-hover-filter: $hamburger-hover-filter;

$hamburger-types: (
  spring,
  spring-r,
);

// Hamburgers menu
@import '~hamburgers/_sass/hamburgers/_base';
@import '~hamburgers/_sass/hamburgers/types/_spring';

// Hamburger
.hamburger {
	position: relative;
	z-index: 99;

	&:focus {
		outline: none;
	}
}

@include media-breakpoint-up(lg) {
	.hamburger {
		display: none;
	}
}