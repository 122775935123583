@import '../../assets/scss/bootstrap';

@keyframes loading {
	 0% { transform: translate3d(0,   0  , 0) }
	10% { transform: translate3d(0, -20px, 0) }
	50% { transform: translate3d(0,   0  , 0) }
}

.appLoader {
	position: fixed;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background-color: $darkBlue;
	visibility: hidden;
	opacity: 0;
	//transition: opacity 0.25s linear, visibility 0s linear 0.25s;
	z-index: 9999;
}

.appLoader.appLoaderVisible {
	visibility: visible;
	opacity: 1;
	transition-delay: 0s;
}

.dot {
	display: inline-block;
	width: 18px;
	height: 18px;
	margin: 30px 10px 0 10px;
	background-color: $blue;
	border-radius: 50%;
	animation: loading 2.5s ease 0s infinite;
}

.dot:nth-of-type(2) { animation-delay: 0.1s }
.dot:nth-of-type(3) { animation-delay: 0.2s }
