@import '../../assets/scss/bootstrap';

.h2 {
	font-size: 1rem;
	color: $blue;
	font-weight: bold;
	letter-spacing: .2em;
}
.h3 {
	color: $darkBlue;
	font-size: 2.1875rem;
}
.h2, .h3 {
	text-transform: uppercase;
}
.separator {
	display: inline-block;
	width: 67px;
	height: 1px;
	background-color: #000;
}
