/**
 * Main navigation style
 */
 @import '../../assets/scss/bootstrap';

 // Navbar
 .navbar {
	 position: sticky;
	 top: 0;
	 left: 0;
	 right: 0;
	 z-index: 999;
	 background: $darkBlue;

	 @include media-breakpoint-up(lg) {
		 height: $navbarHeight;
	 }
 }

 // Top menu
 .topMenu {
	 padding: 0;
	 margin: 0;

	 .topMenu__menu {
		 padding: 0;
		 margin: 0;
	 }

	 .menuItem {
		 list-style: none;

		 &:not(.btnWrap) {
			 a {
				 color: #fff;
				 display: inline-block;
				 position: relative;
				 margin: 0 auto;
				 transform: translateY(0);
				 transition: transform .3s, color .3s;

				 &:after {
					 content: '';
					 position: absolute;
					 left: 0.2em;
					 bottom: -0.2em;
					 right: 0.2em;
					 height: 0;
					 z-index: -1;
					 border-bottom: 2px solid;
					 transform: translateY(0.2em);
					 opacity: 0;
					 transition: transform .3s, opacity .3s;
				 }
			 }

			 &.current-menu-item a,
			 a:hover {
				 color: $blue;
				 text-decoration: none;
				 transform: translateY(-2px);

				 &:after {
					 transform: translateY(0);
					 opacity: 1;
				 }
			 }
		 }

		 .btn {
			 border: none;
		 }
	 }

	 // Mobile menu
	 @include media-breakpoint-down(md) {
		 & {
			 padding-top: 2em;
			 position: fixed;
			 top: 0;
			 left: 0;
			 right: 0;
			 bottom: 0;
			 z-index: 1;
			 text-align: center;
			 font-size: 24px;
			 // background: rgba(4,153,205,.9);
			 background: rgba(4,51,104,.9);
			 transform: translateY(100%);
			 opacity: 0;
			 transition: transform .01s .3s, opacity .2s ease-in .1s;
		 }

		 .topMenu__menu {
			 overflow: auto;
			 position: absolute;
			 top: $navbarHeight;
			 left: 0;
			 bottom: 0;
			 right: 0;
		 }

		 .menuItem {
			 margin: 1em 0;
			 transform: translateY(150px);
			 opacity: 0;
			 transition: opacity .25s ease-in-out, transform .15s ease-out;
			 position: relative;

			 // Prevent from :hover of animated a:after
			 &:after {
				 content: '';
				 position: absolute;
				 bottom: -1em;
				 left: 0;
				 right: 0;
				 z-index: 1;
				 height: 1em;
			 }
		 }

		 $j: 10;
		 @for $i from 1 to 10 {
			 $j: $j - 1;
			 .menuItem:nth-child(#{$i}) {
				 transition-delay: $j * 0.03s;
			 }
		 }
	 }

	 // Desktop menu
	 @include media-breakpoint-up(lg) {
		 & {
			 // margin-top: 12/16 + rem;
			 font-size: 14/16 + rem;
		 }

		 .menuItem {
			 display: inline-block;
			 vertical-align: top;

			 a {
				 padding: 0 1em;
				 line-height: 38/16 + rem;
			 }
		 }

		 .btnWrap .btn {
			 font-size: 14/16 + rem;
			 padding-left: 20/16 + rem;
			 padding-right: 20/16 + rem;
			 font-weight: 30;
		 }
	 }
 }
 @include media-breakpoint-down(md) {
	 .admin-bar .topMenu {
		 margin-top: 46px;

		 @media screen and (min-width: 783px) {
			 margin-top: 32px;
		 }
	 }
	 .topMenuOpened {
		 .topMenu {
			 transition: transform .01s, opacity .4s ease-in;
			 transform: translateY(0%);
			 opacity: 1;

			 .menuItem {
				 transition: opacity .5s ease-in-out, transform .3s ease-out;
				 transform: translateY(0);
				 opacity: 1;
			 }

			 @for $i from 1 to 10 {
					 .menuItem:nth-child(#{$i}) {
						 transition-delay: .1s + $i * 0.06s;
					 }
			 }
		 }
	 }
 }