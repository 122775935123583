@import '../../assets/scss/bootstrap';

.usefulLinks {
	color: #fff;
	h1, h2, h3, h4, h5, h6 {
		text-transform: uppercase;
		font-size: 1.125rem;
		font-weight: bold;
	}
	ul {
		list-style: none;
		padding-left: 0;

		li:before {
			content: '>';
			margin-right: 10px;
		}
		a, li, li:before {
			color: $gray-500;
		}
	}
}