html {
    scroll-behavior: smooth;
}

.img-cover {
	object-fit: cover;
}

.list-unstyled {
	ul {
		list-style: none;
		padding-left: 0;
	}
	&--custom ul {
		li {
			display: inline-block;
			vertical-align: top;
			// width: 45%;
			width: 100%;
			margin-bottom: 1rem;
			padding-left: 1.5rem;
			padding-right: 1.5rem;
			line-height: 1em;

			@include media-breakpoint-up(md) {
				width: 48%;
			}
			@include media-breakpoint-up(xl) {
				padding-right: 4rem;
			}

			&:before {
				content: '';
				display: inline-block;
				width: 15px;
				height: 15px;
				background-image: url('../svg/small-check-icon.svg');
				background-position: center center;
				background-repeat: no-repeat;
				background-size: contain;
				margin-right: .5rem;
				margin-left: -1.5rem;
				vertical-align: middle;
			}
		}
	}
}

.section-shadow {
	&:after {
		content: '';
		display: block;
		width: 100%;
		padding-bottom: 4.5%;
		background-image: url('../img/shadow.png');
		background-repeat: no-repeat;
		background-size: contain;
	}
}

.form-label {
	color: $blue;
	font-weight: bold;
	font-size: 1.25rem;
}

// Bootstrap tabs

.nav-tabs {
	border-top: 1px solid $gray-200;
	border-bottom: 1px solid $gray-200;
	display: flex;
	flex-wrap: nowrap;
	align-items: center;

	.nav-link {
		display: flex;
		align-items: center;
		border: none;
		color: $blue;
		font-weight: bold;
		font-size: 1.125rem;
		padding: 1rem 1rem 1rem 0;
		flex: 1 1 33%;
		align-self: stretch;

		&.active {
			color: $darkBlue;
			border-bottom: 1px solid $darkBlue;
		}
	}
	@include media-breakpoint-down(md) {
		flex-flow: column wrap;
	}
}

.tab-content {
	padding: 48px 0;
}

// Form select fix
select {
	-webkit-appearance: none;
}

// Pagination

.pagination {
	list-style: none;
	display: inline-block;
	margin: 0;
	padding: 0;

	li {
		display: inline-block;
		padding: 0 .5rem;

		&.active {
			font-weight: bold;
			font-size: 1.2em;
		}

		&.disabled a {
			color: $gray-500;
		}
	}
}

.text-center {
  text-align: center;
}

.text-bold {
  font-weight: 900;
}
