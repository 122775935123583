.preview {
	position: relative;
	padding-right: 60px;
	&:hover {
		cursor: pointer;
	}
}

.counter {
	position: absolute;
	right: 0;
	bottom: 0;
}