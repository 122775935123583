@import '../../assets/scss/bootstrap';

// Logo
.logo {
	display: inline-block;
	position: relative;
	z-index: 2;

	// Fix gap under SVG
	@include media-breakpoint-up(lg) {
		margin-bottom: -6px;
	}
}