@import '../../assets/scss/bootstrap';

.discountCircle {
  width: 38px;
  height: 38px;
  padding-top: 10px;
  font-size: 14px;
  font-weight: 900;
  background: $danger;
  color: $white;
  text-align: center;
  border-radius: 20px;
}
