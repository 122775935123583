@import '../../assets/scss/bootstrap';

.input {
	display: block;
	position: relative;
	margin: 0 auto;
	margin-bottom: 32px;

	&:hover {
		cursor: pointer;
	}
}

.label {
	padding-left: 5px;

	&:hover {
		cursor: pointer;
	}
	&:after {
	  left: 0 !important;
	}
}


.labelPrice {
	color: $blue;
}

.labelPersons {
	color: $light;
}

.showMore {
  color: $blue !important;
  cursor: pointer;
  margin-left: 0;
}
