/**
 * Antenna font face
 */

// Antenna Light (used as default)
@font-face {
	font-family: 'Antenna';
	font-style: normal;
	font-weight: 200;
	src: url('../font/FordAntennaCond-Light/FordAntennaCond-Light.eot');
	src: url('../font/FordAntennaCond-Light/FordAntennaCond-Light.eot?#iefix') format('embedded-opentype'),
		url('../font/FordAntennaCond-Light/FordAntennaCond-Light.woff') format('woff'),
		url('../font/FordAntennaCond-Light/FordAntennaCond-Light.ttf') format('truetype');
}

// Antenna Bold
@font-face {
	font-family: 'Antenna';
	font-style: normal;
	font-weight: 700;
	src: url('../font/FordAntennaCond-Bold/FordAntennaCond-Bold.eot');
	src: url('../font/FordAntennaCond-Bold/FordAntennaCond-Bold.eot?#iefix') format('embedded-opentype'),
		url('../font/FordAntennaCond-Bold/FordAntennaCond-Bold.woff') format('woff'),
		url('../font/FordAntennaCond-Bold/FordAntennaCond-Bold.ttf') format('truetype');
}

//Merriweather italic
@import url('https://fonts.googleapis.com/css?family=Merriweather:400i&display=swap&subset=latin-ext');