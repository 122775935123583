@import '../../assets/scss/bootstrap';

.name {
	font-size: 1.125rem;
	color: $darkBlue;
	font-weight: bold;
}

.list {
	list-style: none;
	padding: 0;
	margin: 0;

	li {
		font-size: 1rem;
		color: $light;
		line-height: 1.3em;

		strong {
			font-weight: 300;
			color: $blue;
		}
	}
}

.priceHeadign {
	font-size: 1.125rem;
	color: $light;
}

.price {
	color: $blue;
	font-weight: bold;
}

.priceNoDiscount {
  font-size: 1rem;
  color: $gray-500;
  text-decoration: line-through;
}

.border {
	border-bottom: 1px solid $light;
	padding: 32px 0;
}
