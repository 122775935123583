// Negative margins
@each $breakpoint in map-keys($grid-breakpoints) {
	@include media-breakpoint-up($breakpoint) {
		$infix: breakpoint-infix($breakpoint, $grid-breakpoints);

		@each $size, $length in $spacers {
			.m#{$infix}--#{$size} { margin: -1 * $length !important; }
			.mt#{$infix}--#{$size},
			.my#{$infix}--#{$size} {
				margin-top: -1 * $length !important;
			}

			.mr#{$infix}--#{$size},
			.mx#{$infix}--#{$size} {
				margin-right: -1 * $length !important;
			}

			.mb#{$infix}--#{$size},
			.my#{$infix}--#{$size} {
				margin-bottom: -1 * $length !important;
			}

			.ml#{$infix}--#{$size},
			.mx#{$infix}--#{$size} {
				margin-left: -1 * $length !important;
			}
		}
	}
}