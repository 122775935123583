/** Variables */

// Paths
$imgPath: '../../img';
$svgPath: '../../svg';

// Custom settings
$navbarHeight: 82px;

// Breakpoints, container withs
$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1440px
);
$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1140px,
	xxl: 1300px
);

// Font
$font-family-sans-serif: "Antenna", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
$font-size-base: 1rem;

$h1-font-size:                $font-size-base * 2.5;
$h2-font-size:                $font-size-base * 2.188;
$h3-font-size:                $font-size-base * 1.75;
$h4-font-size:                $font-size-base * 1.5;
$h5-font-size:                $font-size-base * 1.125;
$h6-font-size:                $font-size-base;

// Color system

$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

// Colors
$blue: #0099cc;
$darkBlue: #003366;
$grayBlue: rgba(#20344c, .8);
$red: #ff0533;
$green: darkgreen;
$primary: $blue;
$light: $gray-500;

$theme-colors: () !default;
$theme-colors: map-merge((
	"darkBlue": $darkBlue,
	"gray-200": $gray-200,
	"gray-500": $gray-500,
), $theme-colors);


// Buttons + forms
$border-radius: 1.56rem;
$input-border-radius-sm: 0;

$input-btn-padding-x: 1.25rem;
$btn-padding-x: 2.9rem;
$input-btn-padding-y: .75rem;
$input-btn-padding-y-sm: .35rem;
$custom-select-padding-y: .75rem;

$btn-font-weight: 700;

$input-border-color: $blue;
$input-placeholder-color: $gray-300;

$label-margin-bottom: 0.625rem;
